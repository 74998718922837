import React, { useEffect, useState } from 'react';
import { startsWith } from 'lodash';
import { Link, graphql, navigate } from 'gatsby';
import { FormattedMessage, useIntl } from 'react-intl';
import { useForm, Controller } from 'react-hook-form';
import countries from 'countries-list';
import format from 'date-fns/format';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import Layout from '../../components/Layout';
import Dropdown from '../../components/Dropdown';

import '../../assets/styles/pages/apply-form-page.scss';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const countryCodes = Object.keys(countries.countries);
const countryNames = countryCodes.map((code) => countries.countries[code].name);
const countryNamesOptions = countryNames.sort().map((country) => ({
  value: country,
  label: country,
}));

const FieldValidationErrorMsg = ({ field }) =>
  field ? <div className="error-val">{field.message}</div> : null;

const getCourseDates = (allCourses, course) => {
  console.log('course: ', course);
  switch (course) {
    case 'Gap Year Program':
      return allCourses
        ?.filter(({ node: { data } }) =>
          data?.show_on_these_pages?.some(({ page }) => page === 'apply')
        )
        ?.map(({ node: { data } }) => ({
          value: format(new Date(data?.from), 'MMMM do YYY'),
          label: data?.from,
        }));
    case 'Barcelona Trinity CertTESOL':
      return allCourses
        ?.filter(({ node: { data } }) =>
          data?.show_on_these_pages?.some(({ page }) => page === 'trinity-cert-tesol-course')
        )
        ?.map(({ node: { data } }) => ({
          value: format(new Date(data?.from), 'MMMM do YYY'),
          label: data?.from,
        }));
    case 'DT':
      return monthNames.map((month) => ({ value: month, label: month }));
    case 'DT+S':
      return monthNames.map((month) => ({ value: month, label: month }));

    default:
      return monthNames.map((month) => ({ value: month, label: month }));
  }
};

const ApplyForm = ({ data }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setError] = useState(null);
  const { register, control, handleSubmit, errors, watch } = useForm();
  const [phoneNumberValue, setPhoneNumberValue] = useState('');
  const [isPhoneValid, setIsPhoneValid] = useState(false);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);
  const watchCourse = watch('course');

  const allPrismicCourses = data?.allPrismicCourses?.edges;
  const [dates, setDates] = useState(() => getCourseDates(allPrismicCourses, watchCourse));

  useEffect(() => {
    setDates(getCourseDates(allPrismicCourses, watchCourse));
  }, [watchCourse]);

  const onSubmit = async (data) => {
    // Enable loading
    setIsLoading(true);

    if (
      data.course === 'Seville Trinity CertTESOL' ||
      data.course === 'Barcelona Trinity CertTESOL'
    ) {
      if (data.course === 'Seville Trinity CertTESOL') {
        data.courseLocation = 'Seville';
      }
      if (data.course === 'Barcelona Trinity CertTESOL') {
        data.courseLocation = 'Barcelona';
      }
      data.course = 'CertTESOL';
    }

    try {
      const MailchimpData = {
        FNAME: data.firstName,
        LNAME: data.lastName,
        MMERGE3: data.userLocation, // location
        MMERGE4: new Date().toISOString().split('T')[0], // contact date
        MMERGE5: data.courseDate?.value || '', // Course date
        MMERGE6: data.userNationality.value, // Nationality
        MMERGE7: format(
          new Date(data.userDobYYYY, data.userDobMM - 1, data.userDobDD),
          'MMMM do YYY'
        ), // DOB
        MMERGE8: data.userNative ? 'Native' : 'Non-native', // Native
        MMERGE9: `+${phoneNumberValue}`, // Phone
        MMERGE19: data.developingTeacher || data.studentVisa ? 'Yes' : 'No', // Visa
        MMERGE20: data.accommodation ? 'Yes' : 'No', // Accommodation String
        MMERGE25: data.comments, // Comments
        MMERGE12: data.promotionalCode, // Promo code
        MMERGE15: data.course, // Course
        MMERGE17: 'Application form', // Source
        MMERGE11: data.developingTeacher ? 'Yes' : 'No', //Developing Teacher
        MMERGE13: data.youngLearners ? 'Yes' : 'No', // Young Learners
        MMERGE16: data.tasterSpanish ? 'Yes' : 'No', // Taster Spanish
        MMERGE18: data.intensiveSpanish ? 'Yes' : 'No', // Intensive Spanish
        MMERGE21: data.studentVisa ? 'Yes' : 'No', // Student visa
        MMERGE22: data.accommodation ? 'Yes' : 'No', // Accommodation 2 Radio
        MMERGE23: data.airportPickup ? 'Yes' : 'No', //Airport pickup
        MMERGE24: data.nieAppointmentAndAssistance ? 'Yes' : 'No', // NIE Assistance
      };
      const response = await fetch('/api/crm', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...{ ...data, userPhone: `+${phoneNumberValue}` },
          userFromGoogleAds: window?.userFromGoogleAds,
          userFromFacebookAds: window?.userFromFacebookAds,
          userFromGoOverseas: window?.userFromGoOverseas,
          userFromGoAbroad: window?.userFromGoAbroad,
        }),
      });
      const jsonResponse = await response.json();
      if (jsonResponse?.success && !jsonResponse?.data?.error_code) {
        setError(false);
        window.fbq('track', 'SubmitApplication');
        addToMailchimp(data.userEmail, MailchimpData);
        navigate('/apply/thank-you');
        // setIsSuccess(true);
      } else {
        throw new Error(jsonResponse.error);
      }
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
      setError(error ? error.message || error : 'Oops, something went wrong');
    } finally {
      setIsLoading(false);
    }
  };

  const validatePhoneNumber = (value) => {
    const minDigits = 10;
    const maxDigits = 15;

    const phoneRegex = new RegExp(`^\\+\\d{${minDigits},${maxDigits}}$`);

    return (
      phoneRegex.test(`+${value}`) ||
      'Wrong phone number format. Please enter a valid phone number. +xxxxxxxxxxx'
    );
  };
  console.log(errors);
  return (
    <Layout title={{ id: 'apply.seo.title' }} description={{ id: 'apply.seo.description' }}>
      <div className="apply-form-page">
        <section className="c-first-section c-first-section--md">
          <div className="container">
            <h1 className="title">
              <FormattedMessage id="apply.sec1.title" />
            </h1>
            <div className="wrapper">
              <div className="group">
                <div className="group__disc">
                  <span>1</span>
                </div>
                <div className="group__text">
                  <p className="text__title">
                    <FormattedMessage id="apply.step1.title" />
                  </p>
                  <p className="text__description">
                    <FormattedMessage id="apply.step1.text" />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>2</span>
                </div>
                <div className="group__text">
                  <p className="text__title">
                    <FormattedMessage id="apply.step2.title" />
                  </p>
                  <p className="text__description">
                    <FormattedMessage id="apply.step2.text" />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>3</span>
                </div>
                <div className="group__text">
                  <p className="text__title">
                    <FormattedMessage id="apply.step3.title" />
                  </p>
                  <p className="text__description">
                    <FormattedMessage id="apply.step3.text" />
                  </p>
                </div>
              </div>

              <div className="group">
                <div className="group__disc">
                  <span>4</span>
                </div>
                <div className="group__text">
                  <p className="text__title">
                    <FormattedMessage id="apply.step4.title" />
                  </p>
                  <p className="text__description">
                    <FormattedMessage id="apply.step4.text" />
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="second-section">
          <div className="container">
            <form className="forms" onSubmit={handleSubmit(onSubmit)}>
              <div className="form">
                <h1 className="c-title-22 form__title">
                  <FormattedMessage id="apply.form.title" />
                </h1>
                <h3 className="form__subtitle">
                  <FormattedMessage id="apply.form.subTitle1" />
                </h3>
                <div>
                  <div className="form__radio">
                    <input
                      id="course-radio-1"
                      type="radio"
                      name="course"
                      value="Barcelona Trinity CertTESOL"
                      ref={register({
                        required: 'This field is required.',
                      })}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates(
                            getCourseDates(allPrismicCourses, 'Barcelona Trinity CertTESOL')
                          );
                        }
                      }}
                    />
                    <label htmlFor="course-radio-1">
                      <span className="label">
                        <FormattedMessage id="apply.form.course.option1" />
                      </span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="course-radio-4"
                      type="radio"
                      name="course"
                      value="DT+S"
                      ref={register({
                        required: 'This field is required.',
                      })}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates(getCourseDates(allPrismicCourses, 'DT+S'));
                        }
                      }}
                    />
                    <label htmlFor="course-radio-4">
                      <span className="label">
                        <FormattedMessage id="apply.form.course.option4" />
                      </span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="course-radio-6"
                      type="radio"
                      name="course"
                      value="DT"
                      ref={register({
                        required: 'This field is required.',
                      })}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates(getCourseDates(allPrismicCourses, 'DT'));
                        }
                      }}
                    />
                    <label htmlFor="course-radio-6">
                      <span className="label">
                        <FormattedMessage id="apply.form.course.option3" />
                      </span>
                    </label>
                  </div>

                  <div className="form__radio">
                    <input
                      id="course-radio-7"
                      type="radio"
                      name="course"
                      value="Gap Year Program"
                      ref={register({
                        required: 'This field is required.',
                      })}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates(getCourseDates(allPrismicCourses, 'Gap Year Program'));
                        }
                      }}
                    />
                    <label htmlFor="course-radio-7">
                      <span className="label">Gap Year Program</span>
                    </label>
                  </div>
                  <div className="form__radio">
                    <input
                      id="course-radio-5"
                      type="radio"
                      name="course"
                      value="Not sure"
                      ref={register({
                        required: 'This field is required.',
                      })}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setDates(getCourseDates(allPrismicCourses, 'Not sure'));
                        }
                      }}
                    />
                    <label htmlFor="course-radio-5">
                      <span className="label">
                        <FormattedMessage id="apply.form.course.option5" />
                      </span>
                    </label>
                  </div>
                  <FieldValidationErrorMsg field={errors.course} />
                </div>

                <h3 className="form__subtitle start-date">
                  <FormattedMessage id="apply.form.subTitle2" />
                </h3>
                <SelectCourseDate control={control} courseDates={dates} />
                {/* <Controller
                  name="courseDate"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={getCourseDates()}
                      placeholder="Select your preferred course date"
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                /> */}

                {/* <div className="form__radio">
                  <input
                    id="date-1"
                    type="radio"
                    value={'July 3rd 2023'}
                    name="courseDate"
                    ref={register}
                  />
                  <label htmlFor="date-1">
                    <span className="label">
                      <FormattedMessage id="apply.form.date.option1" />
                    </span>
                  </label>
                </div>
                <div className="form__radio">
                  <input
                    id="date-2"
                    type="radio"
                    value={'July 31st 2023'}
                    name="courseDate"
                    ref={register}
                  />
                  <label htmlFor="date-2">
                    <span className="label">
                      <FormattedMessage id="apply.form.date.option2" />
                    </span>
                  </label>
                </div>
                <div className="form__radio">
                  <input
                    id="date-3"
                    type="radio"
                    value={'September 4th 2023'}
                    name="courseDate"
                    ref={register}
                  />
                  <label htmlFor="date-3">
                    <span className="label">
                      <FormattedMessage id="apply.form.date.option3" />
                    </span>
                  </label>
                </div> */}

                <FieldValidationErrorMsg field={errors.courseDate} />

                <h3 className="form__subtitle form__extension-course">
                  <FormattedMessage id="apply.form.subTitle3" />
                </h3>
                {/* <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-1"
                    type="checkbox"
                    value={true}
                    name="developingTeacher"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-1">
                    <span className="label">
                      <FormattedMessage id="apply.form.extension.option1" />
                    </span>
                  </label>
                </div> */}
                <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-2"
                    type="checkbox"
                    value={true}
                    name="developingTeacherAndSpanish"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-2">
                    <span className="label">
                      <FormattedMessage id="apply.form.extension.option2" />
                    </span>
                  </label>
                </div>
                <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-3"
                    type="checkbox"
                    value={true}
                    name="notSure"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-3">
                    <span className="label">
                      <FormattedMessage id="apply.form.extension.option3" />
                    </span>
                  </label>
                </div>
                {/* <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-2"
                    type="checkbox"
                    value={true}
                    name="youngLearners"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-2">
                    <span className="label">Young Learners</span>
                  </label>
                </div>
                <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-3"
                    type="checkbox"
                    value={true}
                    name="tasterSpanish"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-3">
                    <span className="label">Taster Spanish Lessons</span>
                  </label>
                </div>
                <div className="form__checkbox">
                  <input
                    id="extension-courses-checkbox-4"
                    type="checkbox"
                    value={true}
                    name="intensiveSpanish"
                    ref={register}
                  />
                  <label htmlFor="extension-courses-checkbox-4">
                    <span className="label">Intensive Spanish Course</span>
                  </label>
                </div> */}
              </div>

              <div className="form">
                <h1 className="c-title-22 form__title">
                  <FormattedMessage id="apply.form.title2" />
                </h1>
                <label className="form__first-name">
                  <p className="c-text-14 text-under">
                    <FormattedMessage id="apply.form.about.firstName" />
                  </p>
                  <input
                    className="input__first-name"
                    type="text"
                    placeholder="John"
                    name="firstName"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.firstName} />
                </label>
                <label className="form__last-name">
                  <p className="c-text-14 text-under">
                    {' '}
                    <FormattedMessage id="apply.form.about.lastName" />
                  </p>
                  <input
                    className="input__last-name"
                    type="text"
                    placeholder="Smith"
                    name="lastName"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.lastName} />
                </label>
                <label className="form__first-name">
                  <p className="c-text-14 text-under">
                    {' '}
                    <FormattedMessage id="apply.form.about.email" />
                  </p>
                  <input
                    className="input__first-name"
                    type="email"
                    placeholder="johnsmith@gmail.com"
                    name="userEmail"
                    ref={register({
                      required: 'This field is required.',
                    })}
                  />
                  <FieldValidationErrorMsg field={errors.userEmail} />
                </label>
                <label className="form__last-name phone-number">
                  <p className="c-text-14 text-under">
                    {' '}
                    <FormattedMessage id="apply.form.about.phone" />
                  </p>
                  {/* <input type="tel" name="userPhoneType" className="begin" placeholder="+34" /> */}
                  {/* <input
                    className="input__last-name"
                    type="tel"
                    placeholder="+3478411558"
                    name="userPhone"
                    ref={register({
                      validate: validatePhoneNumber,
                    })}
                  /> */}

                  <PhoneInput
                    className="input__last-name"
                    placeholder="+XX XXX XXX XXX"
                    value={phoneNumberValue}
                    onChange={(value) => setPhoneNumberValue(value)}
                    onBlur={() => setIsPhoneTouched(true)}
                    isValid={(inputNumber, country, countries) => {
                      return countries.some((country) => {
                        const checkValidity =
                          startsWith(inputNumber, country.dialCode) ||
                          startsWith(country.dialCode, inputNumber);

                        setIsPhoneValid(checkValidity);
                        return checkValidity;
                      });
                    }}
                  />
                  {isPhoneTouched &&
                    !isPhoneValid &&
                    validatePhoneNumber(phoneNumberValue) !== true &&
                    validatePhoneNumber(phoneNumberValue).length > 0 && (
                      <div className="error-val">{validatePhoneNumber(phoneNumberValue)}</div>
                    )}
                  {/* <FieldValidationErrorMsg field={errors.userPhone} /> */}
                </label>
                <label className="form__email">
                  <p className="c-text-14 text-under">
                    {' '}
                    <FormattedMessage id="apply.form.about.userLocation" />
                  </p>

                  {/* <input
                    className="input__last-name"
                    type="text"
                    placeholder="e.g. London, UK"
                    name="userLocation"
                    // ref={register}
                    ref={register({
                      required: 'This field is required.',
                    })}

                  /> */}
                  <SelectUserLocation register={register} />
                  <FieldValidationErrorMsg field={errors.userLocation} />
                </label>
                <h3 className="form__subtitle">
                  {' '}
                  <FormattedMessage id="apply.form.about.nativeSpeaker" />
                </h3>
                <div>
                  <div className="form__radio">
                    <input
                      id="english-speaker-radio-1"
                      type="radio"
                      value="Yes"
                      name="userNative"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="english-speaker-radio-1">
                      <span className="label">
                        <FormattedMessage id="apply.form.about.nativeSpeaker.yes" />
                      </span>
                    </label>
                  </div>

                  <div className="form__radio">
                    <input
                      id="english-speaker-radio-2"
                      type="radio"
                      value="No"
                      name="userNative"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <label htmlFor="english-speaker-radio-2">
                      <span className="label">
                        {' '}
                        <FormattedMessage id="apply.form.about.nativeSpeaker.no" />
                      </span>
                    </label>
                  </div>
                  <FieldValidationErrorMsg field={errors.userNative} />
                </div>

                <h3 className="form__subtitle start-date">
                  {' '}
                  <FormattedMessage id="apply.form.about.nationality" />
                </h3>
                {/* <Controller
                  name="userNationality"
                  render={({ ref, ...props }) => (
                    <Dropdown
                      inputRef={ref}
                      options={countryNamesOptions}
                      placeholder={nationalityPlaceholder}
                      isSearchable
                      {...props}
                    />
                  )}
                  control={control}
                  defaultValue={null}
                  rules={{
                    required: 'This field is required.',
                  }}
                /> */}
                <SelectUserNationality
                  countryNamesOptions={countryNamesOptions}
                  control={control}
                />
                <FieldValidationErrorMsg field={errors.userNationality} />

                <div className="date">
                  <h3 className="form__subtitle">
                    <FormattedMessage id="apply.form.about.dateOfBirth" />
                  </h3>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="01"
                      className="input__date input__date--dd"
                      name="userDobDD"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">
                      <FormattedMessage id="apply.form.about.dateOfBirth.dd" />
                    </span>
                  </div>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="02"
                      className="input__date input__date--mm"
                      name="userDobMM"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">
                      <FormattedMessage id="apply.form.about.dateOfBirth.mm" />
                    </span>
                  </div>
                  <div className="date__wrapper">
                    <input
                      type="number"
                      placeholder="1987"
                      className="input__date input__date--yyyy"
                      name="userDobYYYY"
                      ref={register({
                        required: 'This field is required.',
                      })}
                    />
                    <span className="text-fw-600">
                      <FormattedMessage id="apply.form.about.dateOfBirth.yyyy" />
                    </span>
                  </div>
                  <FieldValidationErrorMsg
                    field={errors.userDobDD || errors.userDobMM || errors.userDobYYYY}
                  />
                </div>
              </div>

              <div className="form">
                <h1 className="c-title-22 form__title">
                  <FormattedMessage id="apply.form.subTitle4" />
                </h1>
                <h3 className="form__subtitle">
                  <FormattedMessage id="apply.form.service.title" />
                </h3>
                <div>
                  <div className="form__checkbox">
                    <input
                      id="additional-services-checkbox-1"
                      type="checkbox"
                      value={true}
                      name="accommodation"
                      ref={register}
                    />
                    <label htmlFor="additional-services-checkbox-1">
                      <span className="label">
                        <FormattedMessage id="apply.form.service.accommodation" />
                      </span>
                    </label>
                  </div>
                  <div className="form__checkbox">
                    <input
                      id="additional-services-checkbox-2"
                      type="checkbox"
                      value={true}
                      name="airportPickup"
                      ref={register}
                    />
                    <label htmlFor="additional-services-checkbox-2">
                      <span className="label">
                        {' '}
                        <FormattedMessage id="apply.form.service.airportPickUp" />
                      </span>
                    </label>
                  </div>

                  <div className="form__checkbox">
                    <input
                      id="additional-services-checkbox-3"
                      type="checkbox"
                      value={true}
                      name="nieAppointmentAndAssistance"
                      ref={register}
                    />
                    <label htmlFor="additional-services-checkbox-3">
                      <span className="label">
                        <FormattedMessage id="apply.form.service.nieTie" />
                      </span>
                    </label>
                  </div>
                  <div className="form__checkbox">
                    <input
                      id="additional-services-checkbox-4"
                      type="checkbox"
                      value={true}
                      name="culturalActivity"
                      ref={register}
                    />
                    <label htmlFor="additional-services-checkbox-4">
                      <span className="label">
                        <FormattedMessage id="apply.form.service.culturalActivities" />
                      </span>
                    </label>
                  </div>
                </div>

                <label className="form__first-name promotional-code">
                  <p className="c-text-14 text-under">
                    <FormattedMessage id="apply.form.service.promotionalCode" />
                  </p>
                  {/* <input
                    className="input__first-name"
                    type="text"
                    placeholder="Enter code"
                    name="promotionalCode"
                    ref={register}
                  /> */}
                  {/* <button className="promotional-code__btn">Apply Promotional Code</button> */}
                  <PromotionalCode register={register} />
                </label>

                <label className="form__textarea">
                  <p className="c-text-14 text-under">
                    <FormattedMessage id="apply.form.service.comments" />
                  </p>
                  {/* <textarea
                    className="input__textarea input__textarea--sm"
                    placeholder={commentsPlaceholder}
                    name="comments"
                    ref={register}
                  /> */}
                  <Comments register={register} />
                </label>

                <h3 className="form__subtitle">
                  {' '}
                  <FormattedMessage id="apply.form.service.termsTitle" />
                </h3>
                <div className="form__checkbox terms">
                  <input
                    id="agree-checkbox-1"
                    type="checkbox"
                    value={true}
                    name="agreeRules"
                    ref={register({ required: 'This field is required.' })}
                  />
                  <label htmlFor="agree-checkbox-1">
                    <span className="label">
                      <FormattedMessage
                        id="apply.form.service.terms"
                        values={{
                          a1: (chunk) => (
                            <FormattedMessage id="url.apply.terms">
                              {(locale) => (
                                <Link to={locale[0]} className="red-link">
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                          a2: (chunk) => (
                            <FormattedMessage id="url.web.privacy">
                              {(locale) => (
                                <Link to={locale[0]} className="red-link">
                                  {chunk}
                                </Link>
                              )}
                            </FormattedMessage>
                          ),
                        }}
                      />
                    </span>
                  </label>
                  <FieldValidationErrorMsg field={errors.agreeRules} />
                </div>
              </div>

              <button
                type="submit"
                className="c-btn c-btn--red"
                disabled={
                  Object.keys(errors).length > 0 ||
                  !isPhoneValid ||
                  validatePhoneNumber(phoneNumberValue) !== true
                }
              >
                {isLoading ? (
                  <i className="spinner" />
                ) : (
                  <FormattedMessage id="apply.form.btn.submit" />
                )}
              </button>

              {isSuccess && (
                <div className="success-msg">
                  <span> Your application has been successfully submitted!</span>
                </div>
              )}

              {isError && (
                <div className="error-msg">
                  <span>{isError}</span>
                </div>
              )}
              {Object.keys(errors).length > 0 && (
                <div className="error-msg">
                  <span>Some fields are missing or contain errors.</span>
                </div>
              )}
            </form>
          </div>
        </section>
      </div>
    </Layout>
  );
};

const PromotionalCode = ({ register }) => {
  const intl = useIntl();

  const promoPlaceholder = intl.formatMessage({
    id: 'apply.form.service.promotionalCode.placeholder',
  });

  return (
    <input
      className="input__first-name"
      type="text"
      placeholder={promoPlaceholder}
      name="promotionalCode"
      ref={register}
    />
  );
};

const SelectUserLocation = ({ register }) => {
  const intl = useIntl();

  const userLocationPlaceholder = intl.formatMessage({
    id: 'apply.form.about.userLocation.placeholder',
  });
  return (
    <input
      className="input__last-name"
      type="text"
      placeholder={userLocationPlaceholder}
      name="userLocation"
      // ref={register}
      ref={register({
        required: 'This field is required.',
      })}
    />
  );
};

const Comments = ({ register }) => {
  const intl = useIntl();

  const commentsPlaceholder = intl.formatMessage({
    id: 'apply.form.service.comments.placeholder',
  });
  return (
    <textarea
      className="input__textarea input__textarea--sm"
      placeholder={commentsPlaceholder}
      name="comments"
      ref={register}
    />
  );
};

const SelectCourseDate = ({ control, courseDates }) => {
  const intl = useIntl();

  const selectCoursePlaceholder = intl.formatMessage({
    id: 'apply.form.prefferedCourseDate',
  });

  return (
    <Controller
      name="courseDate"
      render={({ ref, ...props }) => (
        <Dropdown
          inputRef={ref}
          options={courseDates}
          placeholder={selectCoursePlaceholder}
          {...props}
        />
      )}
      control={control}
      defaultValue={null}
      rules={{
        required: 'This field is required.',
      }}
    />
  );
};

const SelectUserNationality = ({ countryNamesOptions, control }) => {
  const intl = useIntl();

  const nationalityPlaceholder = intl.formatMessage({
    id: 'apply.form.about.nationality.placeholder',
  });

  return (
    <Controller
      name="userNationality"
      render={({ ref, ...props }) => (
        <Dropdown
          inputRef={ref}
          options={countryNamesOptions}
          placeholder={nationalityPlaceholder}
          isSearchable
          {...props}
        />
      )}
      control={control}
      defaultValue={null}
      rules={{
        required: 'This field is required.',
      }}
    />
  );
};

export const query = graphql`
  query {
    allPrismicCourses(sort: { order: ASC, fields: data___from }) {
      edges {
        node {
          data {
            show_on_these_pages {
              page
            }
            from(formatString: "D MMMM YYYY")
            to(formatString: "D MMMM YYYY")
            name {
              text
            }
            location
          }
        }
      }
    }
  }
`;

export default ApplyForm;
